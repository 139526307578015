import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { isObject } from 'lodash-es'
import getCSRFToken from '~/core/functions/getCSRFToken'

export const createDefaultClient = (url = '') => {
  const baseURL = `${url}/api`

  const instance = axios.create({
    baseURL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json'
    },
    camelize: false
  })

  instance.interceptors.request.use((config) => {
    config.headers!['X-CSRFToken'] = getCSRFToken()

    if (!config.camelize || config.headers!['Content-Type'] !== 'application/json') {
      return config
    }

    if (config.params) {
      config.params = decamelizeKeys(config.params)
    }

    if (config.data) {
      config.data = decamelizeKeys(config.data)
    }

    return config
  })

  instance.interceptors.response.use((response) => {
    // NOTE: https://giovanniantonaccio.hashnode.dev/configuring-a-camelcase-to-snakecase-parser-with-axios
    if (response.config.camelize &&
      response.headers['content-type'] === 'application/json' &&
      isObject(response.data)) {
      response.data = camelizeKeys(response.data, response.config.camelizeProcessor)
    }

    return response
  })

  return instance
}


import type { ActionTree, MutationTree } from 'vuex'
import { removeNamespace } from '~/core/functions'
import { RATE_ACTIONS, RATE_MUTATIONS } from '~/core/constants'
import { RateStatusV2 } from '~/features/Rates/api/chat.api.enums'
import { RateAboutDetailsDto } from '~/features/Rates/api/chat.api.types'

interface IState {
  about: RateAboutDetailsDto,
  status: RateStatusV2 | ''
}

const initialState = (): IState => ({
  about: {
    avatar: '',
    name: '',
    info: '',
    rateType: '',
    rateObjectId: 0,
    inUserFavourites: null
  },
  status: ''
})

export const state = initialState
export type TRootState = ReturnType<typeof state>

const MUTATIONS_TYPES = removeNamespace('rate/', RATE_MUTATIONS)

const ACTIONS_TYPES = removeNamespace('rate/', RATE_ACTIONS)

export const mutations: MutationTree<TRootState> = {
  [MUTATIONS_TYPES.SET_ABOUT](state, payload: RateAboutDetailsDto) {
    state.about = payload
  },
  [MUTATIONS_TYPES.SET_STATUS](state, status: RateStatusV2) {
    state.status = status
  },
  [MUTATIONS_TYPES.CLEAR](state) {
    Object.assign(state, initialState())
  }
}

export const actions: ActionTree<TRootState, TRootState> = {
  [ACTIONS_TYPES.INIT]({ commit }, payload: IState) {
    commit(MUTATIONS_TYPES.SET_ABOUT, payload.about)
    commit(MUTATIONS_TYPES.SET_STATUS, payload.status)
  }
}

export enum RateType {
  Doctor = 'doctor',
  Clinic = 'lpu',
  Drug = 'drug' // TODO: выпилить вместе с флагом sff_rates_redesign
}

export enum RateGroup {
  Waiting = 'waiting',
  All = 'all'
}

export enum EditType {
  Text = 'change_text',
  Document = 'document',
  Rate = 'change_rate',
  Authorize = 'authorize',
  ChangeScore = 'change_scores',
  ConfirmDocuments = 'confirm_documents',
  Default = 'default'
}

export enum PageType {
  Discuss = 'discussPage',
  Edit = 'editPage',
  Detail = 'detailPage',
  Publish = 'publishPage'
}

export enum CantModifyReasonType {
  HaveNoDocument = 'no_document',
  HaveNoDocumentWithMe = 'remind_me_later',
  DontWantToSend = 'not_provide_document'
}

export enum SkipReason {
  Absence = 'absence',
  Unwilling = 'unwilling'
}

export enum RateScoreType {
  Excellent = 'excellent',
  Good = 'good',
  Normal = 'normal',
  Bad = 'bad',
  Awful = 'awful',
  Empty = 'empty'
}

export enum RateScoreDetailLpuKey {
  Building = 'building',
  Equipment = 'equipment',
  MedStaff = 'medstaff',
  Leisure = 'leisure',
  Food = 'food'
}

export enum RateScoreDetailDoctorKey {
  Osmotr = 'osmotr',
  Efficiency = 'efficiency',
  Friendliness = 'friendliness',
  Informativity = 'informativity',
  RecommendDoctor = 'recommend_doctor',
  RecommendSpecialist = 'recommend_specialist'
}

// TODO: Заменен на RateScoreDetailDoctorKey | RateScoreDetailLpuKey, удалить вместе с флагом sff_rates_redesign
export enum ReviewScoreType {
  Equipment = 'equipment',
  Building = 'building',
  Food = 'food',
  MedStaff = 'medstaff',
  Leisure = 'leisure',
  Osmotr = 'osmotr',
  Efficiency = 'efficiency',
  Friendliness = 'friendliness',
  Informativity = 'informativity',
  RecommendDoctor = 'recommend_doctor',
  RecommendSpecialist = 'recommend_specialist',
  Recommend = 'recommend'
}


import { computed, defineComponent } from '~/bridge'

export default defineComponent({
  name: 'BaseBadge',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    bgColor: {
      type: String,
      default: 'ui-kit-bg-primary'
    },
    /**
     * Добавляет класс font-weight-semi-bold
     * */
    bold: {
      type: Boolean,
      default: false
    },
    /**
     * Значение как у prop rounded для vuetify компонентов
     * */
    rounded: {
      type: [String, Boolean],
      default: '12'
    },
    size: {
      type: Number,
      default: 24
    },
    contentClass: {
      type: String,
      default: 'base-badge'
    }
  },
  emits: ['click'],
  setup(props) {
    const classes = computed(() => {
      if (props.bold) {
        return [`${props.color}--text`, 'font-weight-semi-bold']
      }

      return [`${props.color}--text`]
    })

    return {
      classes
    }
  }
})
